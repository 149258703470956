$primary: #e75213;
$text: #333;
$secondaryText: #666;
$weekText: #888;
$info: #acacac;
$disable: #c3c3c3;
$border: #c9c9c9;
$border2: #dcdcdc;
$spacing: #e8e8e8;
$form-spacing: #ededed;
$background: #f4f4f4;
$from-background: #f8f8f8;
$disable-background: #f1f1f1;
$danger: #f24e4e;
$warning: #ffb400;
$success: #10b900;
$blue: #2d71db;
$secondaryPrimary: #e46a36;
