@import 'src/style/variable.scss';

.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  flex-direction: column;
  color: $primary;
  background-color: rgba($color: #666, $alpha: 0.1);
  z-index: 10;
  span {
    font-size: 16px;
    margin-bottom: 6px;
  }
  svg {
    color: $primary;
  }
}
