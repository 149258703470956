.icon {
  font-size: 14px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
}
