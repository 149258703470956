@import 'src/style/variable.scss';

.protocol {
  :global {
    .adm-checkbox {
      align-items: flex-start;
    }
    .adm-checkbox-content {
      font-size: 14px;
      width: 88%;
      line-height: 22px;
    }
    .adm-checkbox-icon {
      width: 18px;
      height: 18px;
      margin-right: 20px;
      margin-top: 3px;
    }
    .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
      color: $primary;
    }
    span {
      color: $primary !important;
      // text-decoration: underline;
    }
  }
}
.file {
  font-size: 14px !important;
  h1 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
  :global {
    .adm-dialog-body-title {
      font-size: 13px;
    }
  }
}
