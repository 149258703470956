@import 'src/style/variable.scss';

.message {
  position: absolute;
  left: 0;
  right: 0;
  top: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
  color: $info;
  flex-direction: column;
  padding: 0 30px;
  svg {
    font-size: 80px;
    color: $info;
    margin-bottom: 20px;
  }
  span:first-child {
    font-size: 24px;
  }
  span:last-child {
    font-size: 12px;
  }
}
