@import 'src/style/variable.scss';

.input {
  text-align: left;
  :global {
    .adm-input {
      border-bottom: 1px solid $border2;
      margin-bottom: 8px;
      font-size: 15px;
    }
  }
}
