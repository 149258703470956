@import 'src/style/variable.scss';

.button {
  display: flex;
  justify-content: center;
  :global {
    .adm-button-primary {
      min-width: 180px;
      height: 45px;
      padding: 0 10px;
      border-radius: 40px;
      font-size: 18px;
      background-color: $primary;
    }
    .adm-button-disabled {
      background-color: $disable;
    }
    .am-button-active {
      background-color: $primary !important;
    }
  }
}
