@import 'src/style/variable.scss';

.header {
  height: 45px;
  :global {
    .adm-nav-bar {
      background-color: white;
      z-index: 9;
    }
    .am-navbar-title {
      color: $text;
    }
    .am-navbar-left {
      color: $text;
    }
    svg {
      width: 16px !important;
      height: 16px !important;
      // fill: white !important;
    }
  }
}
.navBar {
  position: fixed;
  width: 100%;
}
