@import 'src/style/variable.scss';

.message {
  position: absolute;
  left: 0;
  right: 0;
  top: 180px;
  // bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 26px;
  font-weight: bold;
  color: $info;
  flex-direction: column;
  padding: 0 30px;
  svg {
    font-size: 80px;
    color: $info;
    margin-bottom: 20px;
  }
  span:first-child {
    font-size: 24px;
  }
  span:last-child {
    font-size: 12px;
  }
}
.captcha {
  margin: 10px;
  margin-top: 30px;
  // padding-bottom: 20px;
  text-align: center;
  // height: 244px;
  // border: 1px solid $border;
  :global {
    .adm-button-primary {
      width: 94% !important;
      margin: 0 10px;
      height: 43px;
      border-radius: 4px;
      font-size: 18px;
      background-color: $secondaryPrimary;
    }
    .adm-button-disabled {
      background-color: $disable;
    }
    .am-button-active {
      background-color: $secondaryPrimary !important;
    }
  }
}

.input {
  margin: 0 10px;
  margin-bottom: 12px;
  :global {
    .adm-input {
      border: 1px solid $border;
      height: 38px;
      padding: 0 10px;
      border-radius: 4px;
    }
  }
}

.captchaContent {
  margin: 10px;
}

.captchaImage {
  width: 100%;
  height: 108px;
  margin-bottom: 0;
  // background-color: $primary;
}

.refresh {
  display: flex;
  color: $secondaryText;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  svg {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    fill: $secondaryText;
  }
}

.file {
  width: 95%;
  margin: 0 auto;
  height: calc(70vh);
  overflow: scroll;
  margin-top: 12px;
  margin-bottom: 30px;
  background-color: white;
  div {
    font-size: 13px;
    width: 100% !important;
    margin: 0 !important;
    padding: 20px !important;
    word-wrap: break-word;
    word-break: normal;
  }
  p {
    span {
      font-size: 14px !important;
    }
  }
}

.protocol {
  margin-left: 6px;
}
