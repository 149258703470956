/* .App {
  text-align: center;
} */
@import 'src/style/variable.scss';

@supports (bottom: env(safe-area-inset-bottom)) {
  body,
  .footer {
    box-sizing: content-box;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

body {
  overflow: auto !important;
  background-color: $background;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
}

.adm-mask-content {
  height: 100%;
}

:root:root {
  --adm-color-primary: $primary;
}

.adm-button:not(.adm-button-default).adm-button-fill-none {
  color: $primary;
}

.adm-dialog-mask .adm-dialog-wrap .adm-dialog-body-message {
  line-height: 22px;
  font-size: 14px;
}

.adm-dialog-mask .adm-dialog-wrap .adm-dialog-body-title {
  font-weight: 500;
}
