// 组件共用样式
@import 'src/style/variable.scss';

.component-item {
  margin-bottom: 16px;
}
.component-item-title {
  display: flex;
  color: #666;
  margin-bottom: 5px;
  span:first-child {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.component-required {
  margin-left: 5px;
  color: $danger;
}

.component-hint {
  display: block;
  font-size: 12px;
  color: $warning;
}
.component-example {
  margin-left: 5px;
  color: $blue;
  text-decoration: underline;
}
