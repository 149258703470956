@import 'src/style/variable.scss';

.imageUpload {
  text-align: left;
  position: relative;
  margin-bottom: 18px;
  :global {
    .adm-image-uploader-cell-mask {
      display: none;
    }
    .adm-space-item:first-child {
      .adm-image-uploader-upload-button-icon {
        display: none;
      }
    }
    .adm-space-item:not(:first-child) {
      .adm-image-uploader-cell,
      .adm-image-uploader-upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $from-background;
      }
    }
    .adm-space-item:not(:first-child) {
      margin-top: 12px;
    }
    .adm-image,
    .adm-image-uploader-cell-image {
      background-color: $from-background;
    }
    .adm-image-uploader-upload-button {
      background-color: transparent;
    }
    .adm-image-uploader-upload-button .adm-image-uploader-input {
      background-color: transparent;
    }
    .adm-space,
    .adm-space-wrap,
    .adm-space-horizontal {
      width: 100%;
    }
    .adm-space-item,
    .adm-image-uploader-cell,
    .adm-image-uploader-upload-button,
    .adm-image-uploader-input {
      width: 100%;
      height: 180px;
    }
    .adm-image,
    .adm-image-uploader-cell-image {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    .adm-image-img {
      height: 100%;
      width: auto;
    }
    .adm-image-uploader-cell-delete {
      width: 25px;
      height: 25px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .adm-image-tip {
      width: 100%;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    .adm-space,
    .adm-space-wrap,
    .adm-space-horizontal,
    .adm-image-uploader-space {
      margin-bottom: 6px;
    }
  }
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 180px;
  background-color: $from-background;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: $primary;
  span {
    line-height: 22px;
  }
}
.icon {
  background-color: $primary;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 17.5px;
  margin-bottom: 8px;
  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
}

.bgImg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  opacity: 0.4;
}

.hint {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.strong {
  font-weight: 600;
  font-size: 18px;
  margin: 0 3px;
}

:global {
  .adm-image-viewer-image-wrapper {
    img {
      width: 100% !important;
    }
  }
}
